import React, { Component } from 'react';
import "./Projects.css"
import Post from "./Post"
import Posts from "./Posts/Posts";
import TopNavBar from "./Components/TopNavBar"
class Projects extends Component {
    state = {
        indexKey: 0
    };
    navItemClick = (event) => {
        window.scrollTo(0, 0);
        const boxes = document.querySelectorAll('.selectedProjectNavItem');
        boxes.forEach(box => {
            box.classList.remove("selectedProjectNavItem");
        });
        const boxes2 = document.querySelectorAll('.selectedProjectNavTitle');
        boxes2.forEach(box => {
            box.classList.remove("selectedProjectNavTitle");
        });
        event.target.classList.add("selectedProjectNavItem");
        event.target.getElementsByClassName("projectNavTitle")[0].classList.add("selectedProjectNavTitle");
        const newState = {
            indexKey: event.target.id
        }
        this.setState(newState);
    }
    render() {
        window.scrollTo(0, 0);
        document.title = "Wen Kang | Projects"
        return (
            <div>
                <TopNavBar path="projects"></TopNavBar>
                <div className="projectsOuterWrapper">
                    <div className="projectTop">
                        <div className="projectTitle">
                            <span>Projects </span><span className="titleSlash">//</span>
                        </div>
                        <div className="projectTitlePlaceHolder">
                            <span>Projects </span>
                        </div>
                        <div className="projectContent">
                            <span>Project by {Posts.Posts[this.state.indexKey].by}</span>
                        </div>
                    </div>
                    <div className="projectBottom">
                        <div className="projectNav" onScroll={this.scrollFunction}>
                            {Posts.Posts.map((item, index) => {
                                if (index == 0) { var classN = "projectNavItem selectedProjectNavItem"; var classB = "projectNavTitle selectedProjectNavTitle" } else { var classN = "projectNavItem"; classB = "projectNavTitle" }
                                return (
                                    <div key={index} id={index} className={classN} onClick={this.navItemClick}>
                                        <img className="projectNavThumbNail" src={"ProjectThumbNail/" + item.img} />
                                        <div className="projectNavTextContent">
                                            <span className="projectNavType">{item.type}</span><span>{item.date}</span>
                                            <div className={classB}>{item.title}</div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="projectNavPlaceHolder"></div>
                        <div className="projectDescription">
                            <Post indexKey={this.state.indexKey}></Post>
                        </div>
                    </div>
                </div >

            </div>
        );
    }
}
export default Projects;