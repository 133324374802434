import "./About.css";
import "./App.css";
import TopNavBar from "./Components/TopNavBar";
import { useEffect } from "react";

function About() {
    window.scrollTo(0, 0);
    useEffect(() => {
        document.title = "Wen Kang | About";
    }, []);
    return (
        <div>
            <TopNavBar path="about"></TopNavBar>
            <div className="aboutOuterWrapper" >
                <div className="contentBlock">
                    <div className="lottieImage">
                    </div>
                    <div className="title">
                        <div>A STUDENT FROM SINGAPORE,</div>
                        <span>WHO AM I? </span>
                        <span className="titleSlash">//</span>
                    </div >
                    <div className="content">
                        Hello! I am Wen Kang and currently in the School of ICT at Ngee Ann Polytechnic (NP). I first started to have an interest in coding when I was in secondary 4, so I decided to try out different small projects, like a discord bot and a website. When I was certain I wanted to learn Information Technology(IT), I applied for the Early Admissions Exercise at NP and eventually got accepted in the Information Technology course. Throughout my time in NP, I hope to learn and grow more about IT and the world.
                    </div>
                    <div className="content">
                        During my free time, I would personally embark on small personal projects. Using these projects I would learn to use different tools and APIs. These include Flutter, React, Electron and Firebase. By embarking on these small projects I can explore my interests and build up my knowledge outside of what is taught in School.
                    </div>
                </div>
                <div className="contentBlock">
                    <div className="title">
                        <div>MY ACADEMIC JOURNEY</div>
                        <span>THROUGHOUT THE YEARS </span>
                        <span className="titleSlash">//</span>
                    </div >
                    <div>
                        <span className="educationBlock">
                            <img className="educationImage" src="NP.png" alt="" />
                            <span>
                                <div className="year">
                                    2020
                                </div>
                                <div className="educationDesciption">
                                    Entered <em><b>Ngee Ann Polytechnic</b></em> Information Technology course.
                                </div>
                            </span>
                        </span>
                        <span className="educationBlock">
                            <img className="educationImage" src="CHR.png" alt="" />
                            <span>
                                <div className="year">
                                    2016 - 2019
                                </div>
                                <div className="educationDesciption">
                                    Graduated <em><b>Christ Church Secondary School</b></em>, obtaining a net score of 9 for L1-R4.
                                </div>
                            </span>
                        </span>
                        <span className="educationBlock">
                            <img className="educationImage" src="Siling.png" alt="" />
                            <span>
                                <div className="year">
                                    2012 - 2016
                                </div>
                                <div className="educationDesciption">
                                    Graduated from <em><b>Si Ling Primary School</b></em>, obtaining an aggregate score of 210.
                                </div>
                            </span>
                        </span>
                    </div>
                </div>
                <div className="contentBlock">
                    <div className="title">
                        <div>NOTABLE ACHIEVEMENTS</div>
                        <span>OVER THE YEARS </span>
                        <span className="titleSlash">//</span>
                    </div >
                    <div>
                        <div className="year">
                            2021
                        </div>
                        <ul className="bullet">
                            <li> Director's List, October Semester</li>
                            <li>Edusave Certificate of Academic Achievement 2021</li>
                            <li> Director's List, April Semester</li>
                        </ul>
                        <div className="year">
                            2020
                        </div>
                        <ul className="bullet">
                            <li> Director's List, October Semester</li>
                            <li>CSIT Scholarship (2020 - 2023)</li>
                            <li> Director's List, April Semester</li>
                            <li>NP Merit Award</li>
                            <li>Runner up for innovateSECURE 2020</li>
                        </ul>
                        <div className="year">
                            2019
                        </div>
                        <ul className="bullet">
                            <li>Outstanding Performance in GCE Ordinary Level 2019</li>
                            <li>Edusave Scholarship 2019</li>
                            <li>National Youth Achievement Award (Silver)</li>
                        </ul>
                        <div className="year">
                            Others
                        </div>
                        <ul className="bullet">
                            <li>Young Engineer Award (Silver)</li>
                            <li>Life Saving 1 Award</li>
                            <li>National Youth Achievement Award (Silver)</li>
                            <li>Education Merit Award 2016</li>
                            <li>Certificate of Merit (Australian Math Competition)</li>
                        </ul>
                    </div>
                </div>
                <div>&#10240;</div>
            </div>
        </div>
    );
}
export default About;