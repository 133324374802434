var Posts = [
    {
        title: "Projects Planned",
        type: "Personal",
        date: "----------",
        by: "Wen Kang",
        stack: "",
        img: "planned.png",
        linkName: [],
        linkUrl: [],
        content: [
            {
                type: "subtitle",
                cont: `1. CosmWasm Smart Contract (Rust)`
            },
        ]
    },
    {
        title: "PFD Project (OCBC)",
        type: "School",
        date: "13/2/2022",
        by: "Wen Kang, Keerthana, Jin Yang, Yun Cong, Tung Xuan",
        stack: "Android (Java) | Flask | Python | Firebase | Azure WebApp | Azure PostgreSQL Database | Discord Bot API | Twillio API | Telegram Bot API | Retool",
        img: "OCBC_hackathon.png",
        linkName: ["Github", "Website", "Video Demonstration"],
        linkUrl: ["https://wenkang.dev/", "https://wenkang.dev/", "https://wenkang.dev/"],
        content: [

            {
                type: "subtitle",
                cont: `Week 1`
            },
            {
                type: "image",
                cont: `ATEAM.JPG`
            },
            {
                type: "text",
                cont: `This is the 1st week of Portfolio Development (PFD). Initially, I wanted to choose “Safe and seamless
                    joint-account creation” which is an OCBC sponsored challenge statement. However, the MS Teams channel was
                    already full 15 minutes before briefing stated, so I ended up choosing “Fail-safe fund transfer experience.`
            },
            {
                type: "text",
                cont: `During the team building exercise, my solution was one of the top voted. As a result, I was appointed to be
                    a team leader. When it was time to choose teammates, I chose teammate with the adequate skill needed for the
                    solution. In addition, I made sure that our team was well rounded with students who have taken different
                    modules.`
            },
            {
                type: "text",
                cont: `During the 2nd session, my team started to ideate for more ideas for the solution. This session was very
                    successful as we were able to figure out all the features should have. Furthermore, the work was allocated
                    fairly where each teammate has almost the same about of work. For this project, I was allocated the back-end
                    as I was familiar with back-end frameworks such as python and NodeJS.`
            },
            {
                type: "subtitle",
                cont: `Week 2`
            },
            {
                type: "image",
                cont: `INFRASTRUCTURE.JPG`
            },
            {
                type: "text",
                cont: `During the 1st session, my team met up to further discuss our solution. We came up with a model on how our solution’s infrastructure would look like. By drawing out the solution infrastructure, we were able to better visualize our solution. In addition, the teammates in charge of the front-end managed to mock up an Adobe XD of our app.`
            },
            {
                type: "text",
                cont: `This was also the time where I had to figure out how I should build the back-end. Initially, I wanted to go with NodeJS but I decided of use Python as all my teammates understood the language. To host the code, Flask which is a web framework was used. Flask allows users to connect to a server via HTTP requests such as GET and POST.`
            },
            {
                type: "text",
                cont: `To host this server, I chose to use Azure as the GitHub Student Pack offers free credits that would allow me to host the server for free. During testing, I found out Postman which is a API testing tool cannot connect to servers hosted on local host. To solve this, I used Ngrok which is a software that exposes local server ports to the internet, allowing Postman to connect to my local server via the internet.`
            },
            {
                type: "text",
                cont: `When our group was presenting our solution to the OCBC coach, we received valuable feedback that we used to improve our solution.`
            },
            {
                type: "subtitle",
                cont: `Week 3`
            },
            {
                type: "image",
                cont: `PFDCODE.JPG`
            },
            {
                type: "text",
                cont: `The due date for the assignment is getting closer. I have started to build the required APIs need for the Android App. This is because the Android team needs my APIs to begin their work, as our solution includes both a front-end and back-end portion. As a result, I begin to build the required APIs that are needed for our features that were decieded in week 2.`
            },
            {
                type: "text",
                cont: `In addition, I had to work with Tung Xuan who was in-charge of the notification and OTP system. To integrate his portion with mine, I had to provide him with an API endpoint where the android app can request for a one-time password. By working together to implement this feature, the feature was completed very quickly.`
            },
            {
                type: "text",
                cont: `Furthermore, after helping Tung Xuan with the one-time password (OTP) system, I also helped the Android team develop the one-time password page. This was fairly difficult as there are several steps that must be taken to authenticate the user's OTP. Firstly, the mobile device must request for an OTP from the server. Secondly, the server will generate and send the OTP to the user, then create a unique key which will be returned to the user. Thirdly, the user will input their OTP into the app where the OTP and unique key will then be sent to the server for authentication. Lastly, if the OTP is correct, the server will give an OK sign to the mobile application.`
            },
            {
                type: "subtitle",
                cont: `Week 4`
            },
            {
                type: "image",
                cont: `dbchange.png`
            },
            {
                type: "text",
                cont: `During the 1st team meeting of the week on Wednesday, We demonstrated our application to each other. During this, we noticed many problems and areas of improvements of the application. One of the largest problems is that the database was responding very slowly. This could be because of multiple factors which include long query time to our back-end server or long query time to the database. By running the back-end API on Postman, we noticed that the problem was not the query time of the back-end server, but it was the query time of the database.`
            },
            {
                type: "text",
                cont: `This is because Firebase which is a NoSQL database was originally used for our solution's database. As our data is relational in nature, querying relational data on a NoSQL database takes every long and usually takes multiple queries. As a result, as a team, we decided to change our database to Supabase, which is a SQL database that runs on PostgreSQL. By switching to a SQL database, the querying time improved exceptionally especially for the query that gets all transactions.`
            },
            {
                type: "text",
                cont: `Through this experience. I have learnt the importance of ensuring that the correct database is picked before a project is started. This is because a wrong database could lead to problems down the road like long query times. Luckily, this problem was discovered early and only delayed project development for a day.`
            },
            {
                type: "subtitle",
                cont: `Week 5`
            },
            {
                type: "image",
                cont: `solution.JPG`
            },
            {
                type: "text",
                cont: `During the 1st Hackathon day, My team and I had several goals we plan to finish such as finishing development and testing. During the 1st day, I had several errors in my back-end code, so I worked on my portion during the morning session. In the afternoon, my team brainstormed several new ideas that could be implemented in our solution, then started to implement them. By the end of the day, we finished development of our prototype but have not tested our application for bugs and errors.
                `
            },
            {
                type: "text",
                cont: `For the 2nd day, our team planned to finish the presentation slides and finish testing our solution. During the morning, my team and I began to make our slides for our Hackathon presentation. Our presentation includes a description of our solution and a demostration of our solution. During the afternoon session, our team meet up and begin to start testing our application, where we found alot of errors. Although many errors were discovered, the testing session was still very successful as majority of the bugs and errors were fixed.`
            },
            {
                type: "text",
                cont: `For the Hackathon presentation with OCBC judges, I (Wen Kang) presented the slides, while my friend performed a live demostration of our solution. After the presenation, the OCBC staff provided very insightful feedbacks and improvements. After both OCBC challenge statements has been presented, the OCBC judges announced at my group won the "Top Solution" prize for our challenge statement. This was a surprise as the other teams brought forward amazing ideas during their presentation. Such as Lim Xiang's group's facial recognition for authentication.`
            },
            {
                type: "text",
                cont: `Throughout this 3 day Hackathon, I have learnt alot more about project management and team leading. I realised that the team was more productive during f2f meetings as compared to online meetings as errors and miss-understanding brought up could be immediately rectified. I believe that this Hackathon experience has made me more interested in Information Technology and I look forward to attending future Hackathons such as Hacktoberfest which is hosted by DigitalOcean in the month of October.`
            },
            {
                type: "subtitle",
                cont: `Week 12`
            },

            {
                type: "text",
                cont: `After a long 7 week break from our 1st hackathon, it was time to continue with the PFD Assignment. Over the 7 weeks, our team decided not to work on the project as many of our teammates had exams and projects to submit. On the first Wednesday of the week, our team met up in class face-to-face where we discuss the improvement that will be implemented. We looked back at our lecturers' and OCBC coachs' feedback during the hackathon and had to brainstorm how to improve our current application.`
            },
            {
                type: "text",
                cont: `By considering the feedbacks given and brainstorming ourselves, we have came up with several different features/improvements that can be implemented.`
            },
            {
                type: "text",
                cont: `Firstly, we plan to implement scheduled transaction, which would allow users to schedule a trasaction at a later date.`
            },
            {
                type: "text",
                cont: `Secondly, Giro transaction will allow users to connect themselves with a billing organisation, which will allow the billing organisation to initiate a transaction to pay bills or taxes.`
            },
            {
                type: "text",
                cont: `Thirdly, a QR code transfer method will be implemented. This would allow users to scan a recipient's QR code to transfer money, reducing the need to fill in the recipient's account number, IC number or phone number.`
            },
            {
                type: "text",
                cont: `Fourthly, a discord notification system will be implemented that will notify users about errors and transactions made.`
            },
            {
                type: "text",
                cont: `Fifthly, we will be changing our code architechure to allow multiple account holders to hold several bank accounts such as savings account, checking account or retirement account.`
            },
            {
                type: "text",
                cont: `Sixthly, the database will be migrated to Azure's PostgreSQL database (This will be explain in later weeks).`
            },
            {
                type: "text",
                cont: `Seventhly, a CRUD application will be built which would allow OCBC staff and developers to access the database to troubleshoot issues.`
            },
            {
                type: "text",
                cont: `Eightly, a MAC address tracking will be implemented so that the system will be able to track if the account has been accessed from another device and notify users about unauthorised logins.`
            },
            {
                type: "text",
                cont: `Ninethly, NFC transaction was will allow users to transfer money by tapping each other's phone. This would allow money to be transfered very quickly as the recipient's information does not need to be typed in.`
            },
            {
                type: "text",
                cont: `Lastly, more validation will be applied to the existing code base and the newly implemented features`
            },
            {
                type: "subtitle",
                cont: `Week 13`
            },
            {
                type: "image",
                cont: `PFD_Week13.JPG`
            },
            {
                type: "text",
                cont: `Between week 12 and 13, I have almost finished migrating the database to Azure PostgreSQL. This was a difficult process as the database had to be first set up, then the connection with the database with the server had to be revamped. This means that every code in the database had to be refactored so that it can work with the new database.`
            },
            {
                type: "text",
                cont: `Furthermore, as Azure's database using a Infrastructure as a Service (IaaS) model, it does not come with a front-end application to interact with the database. So, I used Retool, which is an online CRUD application builder to quickly prototype a CRUD application to interact with the new database.`
            },
            {
                type: "text",
                cont: `During the first lesson of the week on Wednesday, we had a meeting with Mr Hendri (OCBC coach), where we showcased our new improvements and features we planned to implemented. After the meeting, Mr Hendri recommended me implement a SQL database feature called "Transactions", which is a SQL function that ensures that consequtive queries either fail together or pass together. This ensures that partial queries such as adding transaction record and updating account balance are not performed seperately.`
            },
            {
                type: "subtitle",
                cont: `Week 14`
            },
            {
                type: "image",
                cont: `PFD_Week14.JPG`
            },
            {
                type: "text",
                cont: `For this week, nothing was really planned. Our team continued with our development, and met up during the Wednesday and Friday lesson to update each other about our progress.`
            },
            {
                type: "text",
                cont: `This was when Tung Xuan told me that he was done with his Discord Notification and needed help in hosting the Discord Bot. Firstly, I tried hosting it on Azure's webapp service. However, the application seems to keep crashing. Trying to troubleshoot the error, I then tried to host the Discord Bot on Heroku servers, which is another web hosting service. However, the same error occured where the application kept crashing.`
            },
            {
                type: "text",
                cont: `After trying to troubleshoot and fix the problem for several days, I finally noticed the error. The error was due to the in-compatability between Flask and Discord.py which caused the server to continuously crash. As there was no documentation or stackoverflow posts on how to fix this issue, I had to further investigate on the issue and come up with a solution myself.`
            },
            {
                type: "text",
                cont: `After investigating more, I notice the problem was caused as both Flask and Discord.py are trying to open a webserver which causes some conflict where the Discord session will be closed if the session has been open for too long. To solve this, I had to work around the problem and implement the Discord bot in a non-ideal method. Instead of leaving the Discord session always open (which is the intended usecase), I only open the session when I need to use the Discord Bot and close it immediately afterwards. Although this isnt the proper way to use the Python Module, by implementing this way, the errors stop occuring.`
            },
            {
                type: "subtitle",
                cont: `Week 15`
            },
            {
                type: "image",
                cont: `PFD_Week15.JPG`
            },
            {
                type: "text",
                cont: `In Week 15, I have completed most of my work. Which include migrating the database and creating the CRUD application. Since some front-end functions are reliant on the back-end server, I had to create the nessasary functions to retieve data from the database. This included the relationship change between Account Holder and Bank Account from 1-1 to 1-m.`
            },
            {
                type: "text",
                cont: `Furthermore, since the submission date is just 1 week away, we met up more frequently to ensure that everyone was up-to-date and finishing their features.`
            },
            {
                type: "text",
                cont: `In one of the meeting, Yun Cong told the team that he did not have time to develop all the features he was allocated to. As a result, Jin Yang took initiative and volunteered to take up that feature. As Jin Yang was unfamilier with the feature, Yun Cong and the team helped him understand how the feature should be implemented.`
            },
            {
                type: "subtitle",
                cont: `Week 16`
            },
            {
                type: "image",
                cont: `PFD_Week16.JPG`
            },
            {
                type: "text",
                cont: `Week 16 is the submission week and Chinese New Year. Since there is an Assignment due at the end of the week, the whole team had their priorities straight as we had to meet during the Chinese New Year period to ensure that the application has been finished and tested.`
            },
            {
                type: "text",
                cont: `On Monday, which is the eve of Chinese New Year, we met in the morning. As some team members still had development work to do, me and Tung Xuan started on the slides. The slides include 4 main portions, the challenge statement, past features, new features/improvements and our pre-recorded demonstration.`
            },
            {
                type: "text",
                cont: `On Wednesday, all our development work was done and everyone started to work on the slides and test the application. Again, me and Tung Xuan worked on the slides, while Jin Yang, Keerthana and Yun Cong tested the Android application.`
            },
            {
                type: "text",
                cont: `On the last day (Friday), our team did one last check and submitted our work. Furthermore, Keerthana also recorded the demonstration video, which includes all our new features we have implemented for this assignment.`
            },
            {
                type: "subtitle",
                cont: `Final thoughts`
            },
            {
                type: "image",
                cont: `PFD_WeekFinal.JPG`
            },
            {
                type: "text",
                cont: `To conclude through this 10 weeks of development (week 1-5 and 12-16), I have learnt how to manage a team to plan, build and improve on a project. As a team leader, I had to take initiative to push my teammates, keep track of date lines, understand both front-end and back-end fully and more.`
            },
            {
                type: "text",
                cont: `PFD has thought me use pick up new skills and use technologies to solve real-world problem. From this module, I learnt how to use Azure's webapp and database services, use Flask to host a server. Furthermore, I have also learnt how to develop a solution with both fron-end and back-end elements seperated.`
            },
            {
                type: "text",
                cont: `I have also learnt to trust my teammates that they are able to implement their respective features. As this semester has been the hardest and most busy for me and the PFD project is very large, the project had to be seperated into disctince sections where it is difficult for me to keep track of the Android team's progress.`
            },
            {
                type: "text",
                cont: `By trusting and working with my teammates, I believe that we achieve more together as compared if we worked seperately. To conclude, I really enjoy this PFD experience and hope to attend more external hackathons like the Shoppee Code League in the future.`
            },
        ]
    },
    {
        title: "S-L Project 2021",
        type: "School",
        date: "6/5/2021",
        by: "Wen Kang",
        stack: "",
        img: "servicelearning.png",
        linkName: ["Slides deck"],
        linkUrl: ["https://connectnpedu-my.sharepoint.com/:p:/g/personal/s10203100_connect_np_edu_sg/Eb4RjEORg8hNhOOacLgMbwIBVbCgUPNSMVrTvtShwe-zoA?rtime=CN_ogKXy2Ug"],
        content: [
            {
                type: "image",
                cont: `slposter.JPG`
            },
            {
                type: "subtitle",
                cont: `Description of the project`
            },
            {
                type: "text",
                cont:
                    `
                The project was to teach a group of seniors from Yong En Care Centre to use HealthHub, a one-stop portal for Singaporeans to access a wide range of health content, rewards and e-services. For this workshop, the plan was to showcase and demonstrate 4 main sections of the application, which is “Health Articles”, “E-Health Booklet”, “Appointment Booking” and “Health Programmes”. By teaching these sections, we hope that the seniors will be able to understand more about the different features in HealthHub and how to use them.
                `
            },
            {
                type: "text",
                cont:
                    `
                    The workshop with Yong En seniors occurred on 3rd June 2021. However due to Covid-19, we were not able to teach the seniors at the Yong En Care Centre. Instead, we had to conduct the workshop via Zoom. To ensure that the presentation was organised smoothly, the class was split into groups of 4-5, where each group will be in-charge of a group of seniors.
                `
            },
            {
                type: "text",
                cont:
                    `
                    As my team knew our target audience were a group of seniors, my group and I knew we had to present the workshop in a manner that they would understand. This means that out presentation should be easy to follow, non-technical and concise. My team also translated the slides so that both english and chinese speaking seniors will be able to understand the presentation.
                `
            },
            {
                type: "text",
                cont:
                    `
                    Through several rehearsals within my team and a dry run with the representives from Yong En, my team members where confident that we would be ready for the actual workshop with the seniors. As a result, t.he workshop during the actual day was conducted smoothly as there were no major problems that arose
                    `
            },
            {
                type: "subtitle",
                cont: `Academic Growth`
            },
            {
                type: "text",
                cont:
                    `
                    The project has taught me the importance of good User Interface / User Experience (UI/UX) in mobile applications. When navigating around the HealthHub application during the project, I noticed several poor UI/UX choices that may be confusing to seniors, like poorly named tabs and confusing navigation. From this, I have realised that UI/UX is especially important in applications that are targeting seniors who may have trouble remembering which buttons to press or would not understand what certain icons represent. As a result, more attention has to be put into UI/UX to ensure that the user will be navigate around the application smoothly.
                `
            },
            {
                type: "text",
                cont:
                    `
                    In addition, I have learnt the importance of understanding your target audience. For this project, the class correctly identified our target audience, which are seniors. As a result, we were able to create our workshop with senior in mind. So, our slides were concise, non-technical and incorporated a lot of screenshots to allow the seniors to understand what is being taught. By correctly identifying our target audience, the presentation went smoothly and the seniors had an enjoyable time learning.
                `
            },
            {
                type: "subtitle",
                cont: `Civic learning`
            },
            {
                type: "text",
                cont:
                    `
                    By talking to the elderlies, my team and I understood some of the problems the seniors faced, like being unable to keep up with technology, not understanding how to use certain government apps, etc. In addition, they also stated their interest in learning about technology as they were also attending other workshops like photo montage making and cloud storage for photos. This was eye-opening as always thought that elderlies do not like to learn new things due to their old age.
                    `
            },
            {
                type: "text",
                cont:
                    `
                    This project has made me notice the importance of multi-language support in web and mobile content. This is because there are still many people in the world whose first language is not English. For example, during the S-L project, the presentation slides included both English and mandarin descriptions. which allowed seniors who only understood mandarin to keep up with the lesson, and learn comfortably. So, by providing different language options, online content can be more widely available to people.
                    `
            },
        ]
    },
    {
        title: "Industry Engagement",
        type: "School",
        date: "1/5/2021",
        by: "Wen Kang",
        stack: "",
        img: "industryengage.png",
        linkName: [],
        linkUrl: [],
        content: [
            {
                type: "image",
                cont: `GoogleKeyNote.png`
            },
            {
                type: "subtitle",
                cont: `Introduction`
            },
            {
                type: "text",
                cont:
                    `
                    In this project, I attended Google I/O and Apple WWDC21. This was to keep up with the recent improvements to products and services that will be released by Apple and Google. What is Google I/O and WWDC21?
                    `
            },
            {
                type: "text",
                cont:
                    `
                    Google I/O is an annual international event hosted by Google to connect developers from all around the world. They conduct keynotes, discussions, workshops, Ask Me Anything (AMA) sessions with an aim to announce new products, services and improvements. While Apple Worldwide Developers Conference (WWDC) is an annual even held by Apple. Apple is a multinational technology company that is known for their computers (MAC), mobile phones (iPhone), iOS operating system, etc. WWDC hosts events that reveals new products and improvements in the Apple platforms and products, to allow developers to connect with each other and catch up with new Apple technologies.
                    `
            },
            {
                type: "subtitle",
                cont: `One takeaway from Google I/O`
            },
            {
                type: "text",
                cont:
                    `
                    In Android 12, Google is releasing Material You. Material You builds on Material Design, which is a design system created by Google to help build consistent and high-quality User Interface / User Experience (UI/UX) for mobile and web applications. Material You personalises the colour of its user’s widgets, buttons and texts by using the user’s wallpaper to derive a colour pallet that suits the mood and feel of the wallpaper. In addition, Material You shares this colour pallets with other applications so that every application can be uniquely coloured.
                    `
            },
            {
                type: "image",
                cont: `materialyou.png`
            },
            {
                type: "subtitle",
                cont: `One takeaway from Apple WWDC21`
            },
            {
                type: "text",
                cont:
                    `
                    In Android 12, Google is releasing Material You. Material You builds on Material Design, which is a design system created by Google to help build consistent and high-quality User Interface / User Experience (UI/UX) for mobile and web applications. Material You personalises the colour of its user’s widgets, buttons and texts by using the user’s wallpaper to derive a colour pallet that suits the mood and feel of the wallpaper. In addition, Material You shares this colour pallets with other applications so that every application can be uniquely coloured.
                    `
            },
            {
                type: "image",
                cont: `inappevents.png`
            },
            {
                type: "subtitle",
                cont: `Impact of Events on future goals`
            },
            {
                type: "text",
                cont:
                    `
                    After going through these industry talks and events, I have learnt a lot more about the industry. I now understand what features and service are important to customers, and what are the new features to look forward to. Through these events, I have come to realise that I have learnt a lot more about kinds of features and services technology firms are trying to improve and release. I also have several key takeaways from these events that have impacted how I view the information technology industry and my future goals.
                    `
            },
            {
                type: "text",
                cont:
                    `
                    Privacy is one big take away from the Google I/O and WWDC21 event. In these events, both Apple and Google announced improvements to their customers privacy where they both introduced privacy dashboards. A privacy dashboard is an app or page where users can see which permissions were used by apps. As these technology companies are putting a lot effort into improvement on user privacy, this means that user privacy is a high priority for users as it allows users to feel safe while using their devices.
                    `
            },
            {
                type: "text",
                cont:
                    `
                    In both Google I/O and WWDC21 keynotes, Apple and Google announced 3d rendering to their Maps. This allows their maps to display buildings in 3d and render objects onto the map itself. These improvements can help developers re-imagine how they can use maps in their applications. For example, developer can now render 3d objects onto maps like Pokestops in Pokémon GO without developing a whole new map system. By looking at the recent improvements in maps, I am very optimistic about future of maps and I look forward to making applications with the new features.
                    `
            },
            {
                type: "subtitle",
                cont: `Conclusion`
            },
            {
                type: "text",
                cont:
                    `
                    To conclude, throughout this assignment, I have attended several industry talks and events such as Google I/O and Apple WWDC21. These events have made me realise that attending these talks are very important as it allows me to keep up with the ever-evolving industry. In addition, these talks also allows me to reflect on what I have been learning in school and allows me to think of more innovative ideas for programming assignments.
                    `
            },
            {
                type: "text",
                cont:
                    `
                    Thinking back, before this assignment, I do not usually attend these kinds events frequently. As a result, I would normally be oblivious to news regarding the technology industry such as new Android versions or changes in iOS. However, after this assignment, I will make an effort to attend these talks especially future Google I/O and Apple WWDC events as they are very meaningful and interesting to me.`
            },
        ]
    },
    {
        title: "Anonymous Telegram ChatBot",
        type: "Personal",
        date: "8/10/2021",
        by: "Wen Kang",
        stack: "Flask, Python, Heroku, FaunaDB",
        img: "telegram-bot2.png",
        linkName: ["Chat bot", "Video Demonstration"],
        linkUrl: ["https://t.me/TestingBot123321Bot", "https://www.youtube.com/watch?v=ASjI1eDL0-s"],
        content: [
            {
                type: "image",
                cont: `tele.JPG`
            },
            {
                type: "text",
                cont: `When users normally chat on telegram, their names and telephone numbers will be displayed to the other recipients. However, by using a chatbot as an intermediary, users can remain anonymous while chatting with each other.`
            },
            {
                type: "text",
                cont: `When the user starts the bot, the server will only store the user's telegram ID as identification and as a means of partnering up users. When the system searches for available partners, 2 users will never be partnered together more than once. This means that each partner will be unique.`
            },
            {
                type: "text",
                cont: `As anonymous chats may lead to unpleasant experiences, some features have been implemented to negate these effects. Firstly, by default have all images and stickers are blocked, however, it can be enabled whenever wanted. Secondly, there is a report feature where users can report the most recent user they have chat to. chat to.`
            },
            {
                type: "text",
                cont: `As this is a passion project, many aspects of the bot may be buggy and unfinished. In addition, the report feature has been implemented, but there is currently no GUI to evaluate these reports.`
            },
            {
                type: "text",
                cont: `When users normally chat on telegram, their names and telephone numbers will be displayed to the other recipients. However, by using a chatbot as an intermediary, users can remain anonymous while chatting with each other.`
            },
            {
                type: "text",
                cont: `When the user starts the bot, the server will only store the user's telegram ID as identification and as a means of partnering up users. When the system searches for available partners, 2 users will never be partnered together more than once. This means that each partner will be unique.`
            },
            {
                type: "text",
                cont: `As anonymous chats may lead to unpleasant experiences, some features have been implemented to negate these effects. Firstly, by default have all images and stickers are blocked, however, it can be enabled whenever wanted. Secondly, there is a report feature where users can report the most recent user they have chat to. chat to.`
            },
            {
                type: "text",
                cont: `As this is a passion project, many aspects of the bot may be buggy and unfinished. In addition, the report feature has been implemented, but there is currently no GUI to evaluate these reports.`
            },

        ]
    },
    {
        title: "TracknShare",
        type: "School",
        date: "1/8/2021",
        by: "Wen Kang, Alan, Mahshuk",
        stack: "Android (Java), Firebase",
        img: "tracknshare.png",
        linkName: ["Google Play Store", "Github"],
        linkUrl: ["https://play.google.com/store/apps/details?id=sg.edu.np.tracknshare", "https://github.com/NPLeeWenKang/TracknShare"],
        content: [
            {
                type: "image",
                cont: `tracknshareplaystore.JPG`
            },
            {
                type: "text",
                cont: `TracknShare is an Android mobile application built using Java that allows users to track their runs and
                share them with others. Using Firebase Authentication and Realtime Database, users can track and share their
                runs on any device.`
            },
            {
                type: "text",
                cont: `When users start their runs via the app, the GPS location will be periodically captured, which will later be
                used when plotting the run on the map. In addition, the GPS location data also allows statistics such as
                distance, speed, and calories burnt to be calculated and displayed to the user.`
            },
            {
                type: "text",
                cont: `After the runs have been stored, users can create and share posts about their runs where other users can
                view details about that run such as location, distance, and much more.`
            },
        ]
    },
    {
        title: "Luminate",
        type: "School",
        date: "19/2/2021",
        by: "Wen Kang, Keerthana",
        stack: "Javascript, Firebase, OpenTrivia Database",
        img: "luminateicon2.png",
        linkName: ["Github", "Website"],
        linkUrl: ["https://github.com/NPLeeWenKang/ID-Asgn3-GP4", "https://npleewenkang.github.io/ID-Asgn3-GP4/"],
        content: [
            {
                type: "image",
                cont: `luminate2.png`
            },
            {
                type: "text",
                cont: `
                Luminate is a project done by my friend and I for our school assignment. Since this is the first time I am working with another person on a coding project, we had to ensure that we are both understood what is needed to be done to ensure that our code was in sync. As a result, we decieded to use Github. This allowed us to contribute to the project whenever we want and ensures that we always have access to the latest version of the project. Since this is the first time using Github for collaborations, it was difficult for both of us to learn how to use it.
                `
            },
            {
                type: "text",
                cont: `
                After figuring out how Github works, we begun to split the work. My friend worked on the front-end, while I worked on the back-end. To ensure that our user data can be accessed on different devices, I decieded to use Firebase Authentication and Firebase Realtime Database. To increase the functionality of the website, I used a Trivia Quiz API. This allowed users to take auto-generate quizzes and allow quiz creators to use questions from a "Question Bank". To view our website, feel free to click the link above.
                `
            },
        ]
    },
    {
        title: "Bow With You",
        type: "Personal",
        date: "2/11/2020",
        by: "Wen Kang",
        stack: "Reactjs, Firebase",
        img: "box-with-you.png",
        linkName: ["Github"],
        linkUrl: ["https://github.com/SpaceyCodes/Box-With-You"],
        content: [
            {
                type: "image",
                cont: `DiscordReplica2.png`
            },
            {
                type: "text",
                cont: `
                Box With You is a React project (WIP). After learning React, I wanted to work on a fairly big project. As I discovered that React was used to make Discord, I felt that that would be an ideal project to embark on. To make the application, I am using Cloud Firestore as my database and using Fireabase authentication as my method of authenticating users.
                `
            },
            {
                type: "text",
                cont: `
                During development, I encountered many problems, however after many attempts of googling, I finally figure the solutions. Some of the many problems that I had to solve was, authenticating users, allowing users to chat and making sure that the whole app was efficient. Even-though efficiency is one of my top priorities, it was difficult to maintain it, as it is difficult to remain efficient while developing a large app. Since I did not use any State Manager, I was physically climbing up and down the tree. So to solve this, I decieded to use a State Manager like Redux. By using Redux, I am able to better manage my states more efficiently.
                `
            },
        ]
    },
    {
        title: "Todo Master",
        type: "Personal",
        date: "2/11/2020",
        by: "Wen Kang",
        stack: "Reactjs, Firebase, react-beautiful-dnd",
        img: "todomaster.png",
        linkName: ["Website"],
        linkUrl: ["https://todo-app-bb61a.firebaseapp.com/"],
        content: [
            {
                type: "image",
                cont: `Todomasterimg.png`
            },
            {
                type: "text",
                cont: `
                Todo Master is a note taking web app. To learn React, I decieded to undertake a project to learn more about it. At the initial stage, I was following a tutorial. However, since I was progressing fairly quickly, I decieded to not follow the tutorial and make the web application by myself. As React is fairly similar to Flutter, I was familiar familiar with States and Components. In addition, as React is a Javascript library, I did not have to learn Javascript. So in general, it was simple to get into React.
                `
            },
            {
                type: "text",
                cont: `
                To ensure that the data is stored between sessions, I used Firebase Realtime Database. I also used Firebase to authenticate my users. To allow for a more seamless user experience, I chose to use React-Beautiful-dnd which is a npm package that helps to ensure that the uses is able to drag and drop the elements in the application.
                `
            },
        ]
    },
    {
        title: "QR code scanner",
        type: "Personal",
        date: "2/11/2020",
        by: "Wen Kang",
        stack: "Flutter, Dart",
        img: "qrcode.png",
        linkName: ["Github"],
        linkUrl: ["https://github.com/SpaceyCodes/Qr_Code_Scanner"],
        content: [
            {
                type: "image",
                cont: `qrcode-image.png`
            },
            {
                type: "text",
                cont: `
                Flutter is Google's UI toolkit for building mobile applications for IOS and Android. Since Flutter compiles into both IOS and Android, only 1 codebase is needed. Flutter is writen in Dart. After learning Flutter, I wanted to embark on a small project, so I chose to make a QR code scanner. As I was new to Flutter, I needed to problem solve a lot and familiarise myself with the syntax.
                `
            },
            {
                type: "text",
                cont: `
                After scanning the QR code, the app would save the 'favourite' links. To do this, I used the phone's internal storage, therefore you do not need to be online to store or retrieve the database.
                `
            },
        ]
    },
];
module.exports = { Posts };