import { useEffect } from "react";
import TopNavBar from "./Components/TopNavBar";
import "./Home.css";

function Home() {
    var counter = 1;
    console.log("New Page" + counter);
    var classA = "homeTitle1";
    var classB = "homeTitle2 removeBorder";
    var classC = "homeTitle3 removeBorder";
    var classD = "homeTitle4 removeBorder";
    var classE = "homeTitlePlaceHolder";
    console.log("final: ", window.firstVisit);
    // if (window.firstVisit) {
    //     console.log("finalT: ", window.firstVisit)
    //     console.log("finalT: ", classA)
    //     console.log("finalT: ", classB)
    //     console.log("finalT: ", classC)
    //     window.addEventListener("webkitAnimationEnd", callfunction);
    // } else {
    //     console.log("finalF: ", window.firstVisit)
    //     classA = "noMove removeBorder";
    //     classB = "noMove removeBorder";
    //     classC = "noMove finalState";
    //     classD = "homeTitlePlaceHolder removeBorder";
    // }

    function callfunction(event) {
        console.log("start" + counter);
        if (counter == 3) {
            window.removeEventListener("webkitAnimationEnd", callfunction);
            document.getElementsByClassName("homeTitle" + counter)[0].classList.add("finalState");
            counter = 1;
            return;
        }
        document.getElementsByClassName("homeTitle" + counter)[0].classList.add("removeBorder");
        counter += 1;
        document.getElementsByClassName("homeTitle" + counter)[0].classList.remove("removeBorder");
    }
    useEffect(() => {
        document.title = "Wen Kang";

        window.addEventListener("webkitAnimationEnd", callfunction);

        return (() => {
            window.removeEventListener("webkitAnimationEnd", callfunction);
        });
    }, []);
    return (
        <div>
            <TopNavBar path="home"></TopNavBar>
            <div className="homeTitleWrapper">
                <div className="homeTitle"><div className={classA}>// Aspiring Programmer</div></div>
                <div>&nbsp;</div>
                <div className="homeTitle"><div className={classB}>// Student at Ngee Ann Polytechnic</div></div>
                <div className={classE}>// Student at Ngee Ann Polytechnic</div>
                <div className="homeTitle"><div className={classC}>// Freelance data analyst</div></div>
                {/* <div>&nbsp;</div>
                <div className="homeTitle"><div className={classD}>// Web3 Enthusiast</div></div> */}
            </div>
        </div>
    );
}
// dwa
export default Home;