import "./Post.css";
import Posts from "./Posts/Posts";
function Projects(prop) {
    var Post = Posts.Posts[prop.indexKey]

    return (
        <div>
            <div className="projectDescriptionTitle">{Post.title}</div>
            <div className="projectDescriptionStack">
                {Post.stack
                    ?
                    <div>
                        <div>Technology stack:</div>
                        <div>{Post.stack}</div>
                    </div>
                    : ""}
            </div>
            <div className="projectDescriptionLinks">
                {Post.linkName.length
                    ?
                    <svg width="15px" height="15px" viewBox="0 0 16 16" fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M4.715 6.542L3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1.001 1.001 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4.018 4.018 0 0 1-.128-1.287z" />
                        <path
                            d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 0 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 0 0-4.243-4.243L6.586 4.672z" />
                    </svg>
                    : ""}
                <span>&nbsp;</span>
                <a className="projectDescriptionLinkItem" href={Post.linkUrl[0]} target={"_Blank"}>{Post.linkName[0]}</a>
                {Post.linkName.map((item, index) => {
                    if (index != 0) {
                        return (
                            <span key={index}>
                                <span>&nbsp;/&nbsp;</span>
                                <a className="projectDescriptionLinkItem" href={Post.linkUrl[index]} target={"_Blank"}>{Post.linkName[index]}</a>
                            </span>
                        )
                    }
                })}
            </div>
            <div>-----</div>
            {Post.content.map((item, index) => {
                if (item.type == "text") {
                    return (
                        <ProjectDescriptionContent key={index} item={item} />
                    )
                } else if (item.type == "subtitle") {
                    return (
                        <ProjectDescriptionSubtitle key={index} item={item} />
                    )
                } else if (item.type == "image") {
                    return (
                        <ProjectDescriptionImage key={index} item={item} />
                    )
                }
            })}
        </div >
    );
}
function ProjectDescriptionContent(prop) {
    return (
        <div className="projectDescriptionContent">
            {prop.item.cont}
        </div>
    );
}
function ProjectDescriptionSubtitle(prop) {
    return (
        <div className="projectDescriptionSubtitle">
            {prop.item.cont}
        </div>
    );
}
function ProjectDescriptionImage(prop) {
    return (
        <div className="projectDescriptionImage">
            <img src={"ProjectPageImage/" + prop.item.cont} />
        </div>
    );
}
export default Projects;