import { Routes, Route, Link, useLocation } from "react-router-dom";
import "./TopNavBar.css";
function TopNavBar(prop) {
    var classA = "";
    var classB = "";
    var classC = "";
    if (prop.path == "home") {
        classA = "selectedItem";
    } else if (prop.path == "about") {
        classB = "selectedItem";
    } else if (prop.path == "projects") {
        classC = "selectedItem";
    }

    return (
        <div>
            <div className="topbar">
                <span className="name">
                    <Link to="/" >Lee Wen Kang</Link>
                </span>
                <span className="menu">
                    <Link to="/" className={"menuItem namedItem " + classA} id='home'>Home</Link>
                    <Link to="/about" className={"menuItem namedItem " + classB} id='about'>About</Link>
                    <Link to='/projects' className={"menuItem namedItem " + classC} id='project'>Project</Link>
                    <a href="" target={"_blank"} className="menuItem menuItemImage" style={{ pointerEvents: "none" }}>
                        <img className="menuImage" src="GitHub.png"></img>
                    </a>
                    <a href="https://www.linkedin.com/in/leewenkang/" target={"_blank"} className="menuItem menuItemImage">
                        <img className="menuImage" src="LinkedIn2.png"></img>
                    </a>
                </span>
            </div>
            <div className="topbarPlaceHolder">
                <span className="name">
                    <Link to="/">Lee Wen Kang</Link>
                </span>
                <span className="menu">
                    <Link to="/" className="menuItem namedItem selectedItem" id='home'>Home</Link>
                    <Link to="/about" className="menuItem namedItem" id='about'>About</Link>
                    <Link to='/projects' className="menuItem namedItem" id='project'>Project</Link>
                    <a href="" target={"_blank"} className="menuItem menuItemImage" style={{ pointerEvents: "none" }}>
                        <img className="menuImage" src="GitHub.png"></img>
                    </a>
                    <a href="https://www.linkedin.com/in/leewenkang/" target={"_blank"} className="menuItem menuItemImage">
                        <img className="menuImage" src="LinkedIn2.png"></img>
                    </a>
                </span>
            </div>
        </div>
    );
}
export default TopNavBar;