import "./App.css";
import About from "./About";
import Projects from "./Projects";
import Home from "./Home";
import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

function App() {
  document.title = "Wen Kang";
  window.firstVisit = true;
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="projects" element={<Projects />} />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </div>

  );
}
export default App;
